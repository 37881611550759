import React from "react";
import { CaseStudyTemplate, CaseStudyTemplateProps } from "@src/components/CaseTemplete";
import * as UseCase from "@src/components/partials/districts-2025/UseCaseProvider";
import AlisalPhoto from "@src/www/districts/_assets/alisal-photo.jpg";
import AlisalLogoWhite from "@src/www/districts/_assets/alisal_white.png";
import AlisalIcon from "@src/www/districts/_assets/alisal-icon.png";
import AlisalHeadshot from "@src/www/districts/_assets/alisal-headshot.jpg";
import getLocale from "@src/utils/getLocale";

const MolinaCoalValley = ({ pageContext }) => {
  const c = UseCase.useGetCase(2);

  const userLanguage = getLocale(pageContext);
  const noIndex = ["en", "en-us"].includes(userLanguage) ? false : true;

  const molinaCoalValley: CaseStudyTemplateProps = {
    id: "alisal",
    shouldIndex: noIndex,
    hero: {
      image: AlisalPhoto,
      title: c.title,
      logo: AlisalLogoWhite,
    },
    person: {
      image: AlisalHeadshot,
      name: c.quote_person_name,
      title: c.quote_person_title,
      quote: c.quote,
    },
    content: c.content,
    summary: [
      {
        text: c.stat_1_text,
        value: c.stat_1_value,
      },
      {
        text: c.stat_2_text,
        value: c.stat_2_value,
        icon: AlisalIcon,
      },
      {
        text: c.stat_3_text,
        value: c.stat_3_value,
      },
    ],
  };

  return <CaseStudyTemplate {...molinaCoalValley} />;
};

export default UseCase.withProvider(MolinaCoalValley);
